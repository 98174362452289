export enum TypesPage {
  confirm = "confirm",
  change = "change",
}

export interface IMeeting {
  subject: string
  organizer: string
  date: string
  timeFrom: string
  timeTo: string
  description: string
}

//типы участия во встрече
export enum TypesMeet {
  offline = "Оффлайн",
  online = "Онлайн",
  mixed = "Смешанный",
}

//варианты надписей на кнопках
export enum VariantsBtns {
  offline = "Приму участие оффлайн",
  online = "Приму участие онлайн",
  refuse = "Не смогу принять участие",
  change = "Изменить свое решение",
}
