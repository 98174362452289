import { applyMiddleware, combineReducers, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { typePage } from "./redusers/typePage"
import { meeting } from "./redusers/meeting"
import { user } from "./redusers/user"
import { popup } from "./redusers/popup"

const rootReduser = combineReducers({
  typePageReduser: typePage,
  meetingReduser: meeting,
  userReduser: user,
  popupReduser: popup,
})

export const store = createStore(rootReduser, composeWithDevTools(applyMiddleware(thunk)))
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
