import { MeetingAPI } from "./Api"
import { isEmpty } from "lodash"
import { Form } from "./Form"
import styles from "./Meeting.module.scss"

const errMessage = "Произошла ошибка. Приносим извинения."
const noIdMessage = "Поверьте правильность ссылки, идентификатор встречи не обнаружен"

const Meeting = () => {
  const apiBaseURL: string | undefined = process.env.REACT_APP_MEETING_BASE_URL?.trim()

  if (isEmpty(apiBaseURL)) {
    console.error("required env vars not found")
    return (
      <div className={styles.error}>
        <div>{errMessage}</div>
      </div>
    )
  }

  //получение UUID участия в собрании
  const urlParams = new URLSearchParams(window.location.search)
  const participationId = urlParams.get("id")
  if (!participationId) {
    return (
      <div className={styles.error}>
        <div>{noIdMessage}</div>
      </div>
    )
  }

  return <Form api={new MeetingAPI(apiBaseURL!)} participationId={participationId} />
}

export default Meeting
