import React from "react"
import "./style.scss"
import "moment/locale/ru"
import ru_RU from "antd/lib/locale/ru_RU"
import { ConfigProvider } from "antd"
import { Provider } from "react-redux"
import { store } from "./store/store"
import App from "./App"
import { MeetingAPI } from "../Api"

type Props = {
  api: MeetingAPI
  participationId: string
}

const MainContent = ({ api, participationId }: Props) => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={ru_RU}>
        <section className={"mainContent"}>
          <section className={"mainbg meet"}>
            <App api={api} participationId={participationId} />
          </section>
        </section>
      </ConfigProvider>
    </Provider>
  )
}

export default MainContent
