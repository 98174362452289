import PopupActionTypes from "../actionTypes/popup"

export enum PopupState {
  show = "show",
  hide = "hide",
}

export interface IPopupState {
  popupState: PopupState
}

export interface IPopupTriggerAction {
  type: PopupActionTypes.POPUP_TRIGGER
  payload: PopupState
}

export type PopupActions = IPopupTriggerAction
