import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import "moment/locale/ru"
import ru_RU from "antd/lib/locale/ru_RU"
import { ConfigProvider } from "antd"
// import {Provider} from "react-redux";
// import {store} from "./store/store";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"

const federationURL = "http://federation.backend.ekomobile.ru:80"

const client = new ApolloClient({
  uri: federationURL,
  cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  // <Provider store={store}>
  <ConfigProvider locale={ru_RU}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ConfigProvider>
  // </Provider>
)

