import React from "react"
import styles from "./popupChange.module.scss"
import { VariantsBtns } from "../../types"
import ThreeButtons from "../UI/threeButtons/ThreeButtons"

type Props = {
  onlineHandler: () => void
  offlineHandler: () => void
  refuseHandler: () => void
}

const PopupChange = ({ onlineHandler, offlineHandler, refuseHandler }: Props) => {
  const onlineParticipationHandler = () => {
    onlineHandler()
  }
  const offlineParticipationHandler = () => {
    offlineHandler()
  }
  const refuseParticipationHandler = () => {
    refuseHandler()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ThreeButtons
          text1={VariantsBtns.offline}
          btn1Click={offlineParticipationHandler}
          text2={VariantsBtns.online}
          btn2Click={onlineParticipationHandler}
          text3={VariantsBtns.refuse}
          btn3Click={refuseParticipationHandler}
        />
      </div>
    </div>
  )
}

export default PopupChange
