import { IAddTypePageAction } from "../types/typePage"
import TypePageActionTypes from "../actionTypes/typePage"
import { TypesPage } from "../../types"

export const addTypePageActionCreator = (typePage: TypesPage): IAddTypePageAction => {
  return {
    type: TypePageActionTypes.ADD_TYPE_PAGE,
    payload: typePage,
  }
}
