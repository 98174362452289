import React, { ReactNode } from "react"
import { MeetingAPI } from "./Api"
import MainContent from "./mainContent/MainContent"

interface IFormProps {
  api: MeetingAPI
  participationId: string
}

interface IFormState {}

export class Form extends React.Component<IFormProps, IFormState> {
  constructor(props: IFormProps, context: IFormState) {
    super(props, context)

    this.state = {} as IFormState
  }

  render(): ReactNode {
    return <MainContent api={this.props.api} participationId={this.props.participationId} />
  }
}
