import React from "react"
import styles from "./button.module.scss"
import { BtnColor } from "./types"
import { Button as ButtonAnt } from "antd"

type Props = {
  color: BtnColor
  children: React.ReactNode
  btnClick: () => void
}

const Button = ({ color, children, btnClick }: Props) => {
  const btnHandler = () => {
    btnClick()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={[styles.buttonWrap, styles[color]].join(" ")}>
          <ButtonAnt type={"primary"} onClick={btnHandler} size="middle">
            {children}
          </ButtonAnt>
        </div>
      </div>
    </div>
  )
}

export default Button
