import { IPopupState, PopupActions, PopupState } from "../types/popup"
import PopupActionTypes from "../actionTypes/popup"

const defaultState: IPopupState = {
  popupState: PopupState.hide,
}

export const popup = (state = defaultState, action: PopupActions): IPopupState => {
  switch (action.type) {
    case PopupActionTypes.POPUP_TRIGGER:
      return { ...state, popupState: action.payload }
    default:
      return state
  }
}
