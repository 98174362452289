import { IUserState, UserActions } from "../types/user"
import UserActionTypes from "../actionTypes/user"

const defaultState: IUserState = {
  user: undefined,
  type: undefined,
}

export const user = (state = defaultState, action: UserActions): IUserState => {
  switch (action.type) {
    case UserActionTypes.ADD_USER:
      return { ...state, user: action.payload }
    case UserActionTypes.ADD_TYPE:
      return { ...state, type: action.payload }
    default:
      return state
  }
}
