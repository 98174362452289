import React from "react"
import styles from "./popup.module.scss"
import { useTypedDispatch, useTypedSelector } from "../../../store/typedHooks"
import { popupTriggerActionCreator } from "../../../store/actionCreators/popup"
import { PopupState } from "../../../store/types/popup"

type Props = {
  children: React.ReactNode
  header: string
}

const Popup = ({ children, header }: Props) => {
  const dispatch = useTypedDispatch()
  const popupState = useTypedSelector((state) => state.popupReduser.popupState)

  function closeHandler(event: React.MouseEvent<HTMLDivElement>) {
    dispatch(popupTriggerActionCreator(PopupState.hide))
  }

  return (
    <div className={[styles.container, styles[popupState]].join(" ")}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <h1>{header}</h1>
          </div>
          <div className="hrLine"></div>
          <div className={styles.info}>{children}</div>
        </div>
      </div>
      <div className={styles.shadow} onClick={closeHandler}></div>
    </div>
  )
}

export default Popup
