import { ITypePageState, TypePageActions } from "../types/typePage"
import TypePageActionTypes from "../actionTypes/typePage"

const defaultState: ITypePageState = {
  typePage: undefined,
}

export const typePage = (state = defaultState, action: TypePageActions): ITypePageState => {
  switch (action.type) {
    case TypePageActionTypes.ADD_TYPE_PAGE:
      return { ...state, typePage: action.payload }
    default:
      return state
  }
}
