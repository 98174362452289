import { FetchError } from "../../Error"
import { Participation } from "./Participation"
import { ParticipationCommitRequest } from "./ParticipationCommit"

export class MeetingAPI {
  constructor(private baseUrl: string) {}

  /**
   * Возвращает информацию об участии в собрании
   *
   * @param id ID участия в собрании
   */
  async participationInfo(id: string): Promise<Participation> {
    return await this.get<Participation>(`/participation/${id}`)
  }

  /**
   * Сохраняет решение об участии в собрании
   *
   * @param id ID участия в собрании
   * @param request данные о намерении участвовать в собрании
   */
  async participationCommit(id: string, request: ParticipationCommitRequest): Promise<Participation> {
    return await this.post(`/participation/${id}`, request)
  }

  private async get<Response>(methodUrl: string): Promise<Response> {
    return this.request<Response>(methodUrl, {
      method: "GET",
    })
  }

  private async post<Request, Response>(methodUrl: string, body: Request): Promise<Response> {
    return this.request<Response>(methodUrl, {
      method: "POST",
      body: JSON.stringify(body),
    })
  }

  private async request<R>(methodUrl: string, params: RequestInit): Promise<R> {
    const url: string = `${this.baseUrl}${methodUrl}`

    params.headers = new Headers(params.headers)
    params.headers.set("Content-Type", "application/json")
    // На бэке проверяется пока что только наличие заголовка, его содержимое может быть любым.
    params.headers.set("X-Auth-Client-Key", "CSRF protection")

    const httpResp = await fetch(url, params)

    if (!httpResp.ok) {
      console.log(httpResp.statusText)
      throw new FetchError(httpResp.statusText)
    }

    return (await httpResp.json()) as R
  }
}
