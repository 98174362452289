import { IMeetingState, MeetingActions } from "../types/meeting"
import MeetingActionTypes from "../actionTypes/meeting"

const defaultState: IMeetingState = {
  meeting: undefined,
}

export const meeting = (state = defaultState, action: MeetingActions): IMeetingState => {
  switch (action.type) {
    case MeetingActionTypes.ADD_MEETING:
      return { ...state, meeting: action.payload }
    default:
      return state
  }
}
