import React from "react"
import styles from "./threeButtons.module.scss"
import Button from "../button/Button"
import { BtnColor } from "../button/types"

type Props = {
  text1: string
  btn1Click: () => void
  text2: string
  btn2Click: () => void
  text3: string
  btn3Click: () => void
}

const ThreeButtons = ({ text1, btn1Click, text2, btn2Click, text3, btn3Click }: Props) => {
  const btn1Handler = () => {
    btn1Click()
  }
  const btn2Handler = () => {
    btn2Click()
  }
  const btn3Handler = () => {
    btn3Click()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.firstLine}>
          <Button color={BtnColor.red} btnClick={btn1Handler}>
            {text1}
          </Button>
          <Button color={BtnColor.green} btnClick={btn2Handler}>
            {text2}
          </Button>
        </div>
        <div className={styles.secondLine}>
          <Button color={BtnColor.blue} btnClick={btn3Handler}>
            {text3}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ThreeButtons
