import { IAddMeetingAction } from "../types/meeting"
import MeetingActionTypes from "../actionTypes/meeting"
import { Meeting } from "../../../Api/Participation"

export const addMeetingActionCreator = (meeting: Meeting | undefined): IAddMeetingAction => {
  return {
    type: MeetingActionTypes.ADD_MEETING,
    payload: meeting,
  }
}
