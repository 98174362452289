import { IAddTypeAction, IAddUserAction } from "../types/user"
import UserActionTypes from "../actionTypes/user"
import { TypesMeet } from "../../types"

export const addUserActionCreator = (user: string): IAddUserAction => {
  return {
    type: UserActionTypes.ADD_USER,
    payload: user,
  }
}

export const addTypeActionCreator = (type: TypesMeet): IAddTypeAction => {
  return {
    type: UserActionTypes.ADD_TYPE,
    payload: type,
  }
}
