import React, { useState } from "react"
import styles from "./popupRefuse.module.scss"
import Button from "../UI/button/Button"
import { BtnColor } from "../UI/button/types"
import { Input } from "antd"
import { IReasoneRefuse, TypeBtnRefuse } from "../page/Page"

const { TextArea } = Input

type Props = {
  reasoneRefuseHandler: (reasoneRefuse: IReasoneRefuse) => void
}

const PopupRefuse = ({ reasoneRefuseHandler }: Props) => {
  const [value, setValue] = useState("")

  const cancelHandler = () => {
    let reasoneRefuse: IReasoneRefuse = {
      type: TypeBtnRefuse.cancel,
      description: "",
    }
    reasoneRefuseHandler(reasoneRefuse)
  }
  const saveHandler = () => {
    let reasoneRefuse: IReasoneRefuse = {
      type: TypeBtnRefuse.save,
      description: value,
    }
    reasoneRefuseHandler(reasoneRefuse)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.description}>Сообщите пожалуйста причину, по которой не сможете участвовать:</p>
        <TextArea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder=""
          autoSize={{ minRows: 5, maxRows: 5 }}
        />
        <div className={styles.btns}>
          <Button color={BtnColor.red} btnClick={cancelHandler}>
            Отменить
          </Button>
          <Button color={BtnColor.green} btnClick={saveHandler}>
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PopupRefuse
