import "./App.scss"
import Meeting from "./Meeting/Meeting"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

const router = createBrowserRouter(
  [
    {
      path: "/participation",
      element: (
        <div className="App">
          <div className={"container"}>
            <Meeting />
          </div>
        </div>
      ),
    },
  ],
  {}
)

function App() {
  return <RouterProvider router={router} />
}

export default App
