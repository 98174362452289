export interface Participation {
  id: string
  meeting: Meeting
  intention: Intention | null
  participator: Participator
}

export interface Intention {
  intention: ParticipantIntention
  createdAt: string
  comment: string | null
}

export enum ParticipantIntention {
  NO = "NO",
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
}

export interface Meeting {
  typeId: string
  timeRange: TimeRange
  subject: string
  description: string | null
  room: Room | null
  software: Software | null
  onlineLink: string | null
  organizer: Organizer | null
  participators: Array<Participator>
}

export interface TimeRange {
  from: string
  to: string
}

export interface Room {
  name: string
}

export interface Software {
  name: string
}

export interface Organizer {
  id: string
  fio: string | null
}

export enum ParticipatorType {
  MeetingParticipationUser = "MeetingParticipationUser",
  MeetingParticipationContact = "MeetingParticipationContact",
  MeetingParticipationSimple = "MeetingParticipationSimple",
}

export interface Participator {
  __typename: ParticipatorType
}

export interface ParticipatorUser extends Participator {
  userId: string
  fio: string | null
}

export interface ParticipatorContact extends Participator {
  contactId: string
  firstName: string | null
  lastName: string | null
  patronymicName: string | null
  companyName: string | null
}

export interface ParticipatorSimple extends Participator {
  name: string
}
