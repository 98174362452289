import React, { useCallback, useEffect, useState } from "react"
import "./App.scss"
import { useTypedDispatch, useTypedSelector } from "./store/typedHooks"
import { addTypePageActionCreator } from "./store/actionCreators/typePage"
import { addMeetingActionCreator } from "./store/actionCreators/meeting"
import { addTypeActionCreator, addUserActionCreator } from "./store/actionCreators/user"
import { TypesMeet, TypesPage } from "./types"
import Page from "./components/page/Page"
import { MeetingAPI } from "../Api"
import {
  Meeting,
  Participation,
  ParticipatorContact,
  ParticipatorSimple,
  ParticipatorType,
  ParticipatorUser,
} from "../Api/Participation"
import { FetchError } from "../../Error"

type Props = {
  api: MeetingAPI
  participationId: string
}

function App({ api, participationId }: Props) {
  const [participations, setParticipations] = useState<Participation | undefined>()
  const [typePage, setTypePage] = useState<TypesPage>(TypesPage.confirm)
  const [user, setUser] = useState<string>("")
  const [typeParticipation, setTypeParticipation] = useState<TypesMeet>(TypesMeet.mixed)
  const [meeting, setMeeting] = useState<Meeting | undefined>(undefined)
  const [apiError, setApiError] = useState<string>("")

  const getParticipations = useCallback(
    async (id: string) => {
      let participations
      try {
        participations = await api.participationInfo(id)
      } catch (e) {
        setApiError((e as FetchError).message)
        return
      }
      setParticipations(participations)
      setTypePage(participations.intention ? TypesPage.change : TypesPage.confirm)
      setTypeParticipation(TypesMeet[participations?.meeting.typeId as keyof typeof TypesMeet])
      setMeeting(participations?.meeting)
      //для получения имени участника нужно понять какой тип участника, т.к. у разных типов разные поля
      const participatorWithUnknownType = participations.participator
      const partType = participatorWithUnknownType.__typename
      if (partType === ParticipatorType.MeetingParticipationUser) {
        let participator = participatorWithUnknownType as ParticipatorUser
        let name = participator.fio ?? ""
        setUser(name)
      } else if (partType === ParticipatorType.MeetingParticipationContact) {
        let participator = participatorWithUnknownType as ParticipatorContact
        let firstName = participator.firstName ?? ""
        let lastName = participator.lastName ?? ""
        let name = firstName + lastName
        setUser(name)
      } else if (partType === ParticipatorType.MeetingParticipationSimple) {
        let participator = participatorWithUnknownType as ParticipatorSimple
        let name = participator.name ?? ""
        setUser(name)
      }
    },
    [api]
  )

  useEffect(() => {
    getParticipations(participationId)
  }, [participationId, getParticipations])

  const dispatch = useTypedDispatch()
  const typePageInStore = useTypedSelector((state) => state.typePageReduser.typePage)
  const meetingInStore = useTypedSelector((state) => state.meetingReduser.meeting)
  const userInStore = useTypedSelector((state) => state.userReduser.user)
  const typeParticipationInStore = useTypedSelector((state) => state.userReduser.type)

  useEffect(() => {
    dispatch(addTypePageActionCreator(typePage))
    dispatch(addTypeActionCreator(typeParticipation))
    dispatch(addMeetingActionCreator(meeting))
    dispatch(addUserActionCreator(user))
  }, [participations, dispatch, typePage, typeParticipation, meeting, user])

  if ((!typePageInStore || !meetingInStore || !userInStore || !typeParticipationInStore) && !apiError) {
    return null
  }

  return (
    <div className="Meeting__App">
      <div className={"Meeting__container"}>
        {apiError ? (
          <>
            <h1>Что-то пошло не так!</h1>
            <p className="error">{apiError}</p>
          </>
        ) : (
          <>
            <h1>Добрый день, {userInStore}!</h1>
            <div className="hrLine"></div>
            <Page api={api} participationId={participationId} />
          </>
        )}
      </div>
    </div>
  )
}

export default App
