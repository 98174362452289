import { useEffect, useState } from "react"
import styles from "./page.module.scss"
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks"
import { TypesPage, VariantsBtns } from "../../types"
import Button from "../UI/button/Button"
import { BtnColor } from "../UI/button/types"
import ThreeButtons from "../UI/threeButtons/ThreeButtons"
import Popup from "../UI/popup/Popup"
import { popupTriggerActionCreator } from "../../store/actionCreators/popup"
import { PopupState } from "../../store/types/popup"
import PopupRefuse from "../popupRefuse/PopupRefuse"
import PopupChange from "../popupChange/PopupChange"
import moment from "moment"
import { MeetingAPI } from "../../../Api"
import { ParticipantIntention } from "../../../Api/Participation"
import { v4 as uuidv4 } from "uuid"
import { FetchError } from "../../../../Error"

export enum TypeBtnRefuse {
  cancel = "отмена",
  save = "сохранить",
}

export interface IReasoneRefuse {
  type: TypeBtnRefuse
  description: string
}

type Props = {
  api: MeetingAPI
  participationId: string
}

const Page = ({ api, participationId }: Props) => {
  const dispatch = useTypedDispatch()

  const typeParticipation = useTypedSelector((state) => state.userReduser.type)?.toLowerCase()
  const typePage = useTypedSelector((state) => state.typePageReduser.typePage)
  const meeting = useTypedSelector((state) => state.meetingReduser.meeting)

  const [apiError, setApiError] = useState<string>("")

  const [btnClicked, setBtnClicked] = useState<VariantsBtns | undefined>(undefined)
  const [thanks, setThanks] = useState<boolean>(false)

  const [date, setDate] = useState<string>("")
  const [timeFrom, setTimeFrom] = useState<string>("")
  const [timeTo, setTimeTo] = useState<string>("")

  useEffect(() => {
    const dateFromLocal = moment(meeting?.timeRange.from)
    const dateToLocal = moment(meeting?.timeRange.to)
    setDate(dateFromLocal.format("DD-MM-YYYY"))
    setTimeFrom(dateFromLocal.format("hh:mm"))
    setTimeTo(dateToLocal.format("hh:mm"))
  }, [meeting])

  const onlineParticipationHandler = async () => {
    setBtnClicked(VariantsBtns.online)
    dispatch(popupTriggerActionCreator(PopupState.hide))
    //вызов функции отправки решения (Онлайн)
    try {
      await api.participationCommit(participationId, {
        correlationId: uuidv4(),
        actionId: uuidv4(),
        intention: ParticipantIntention.ONLINE,
        comment: null,
      })
    } catch (e) {
      setApiError((e as FetchError).message)
      return
    }
    setThanks(true)
  }
  const offlineParticipationHandler = async () => {
    setBtnClicked(VariantsBtns.offline)
    dispatch(popupTriggerActionCreator(PopupState.hide))
    //вызов функции отправки решения (Офлайн)
    try {
      await api.participationCommit(participationId, {
        correlationId: uuidv4(),
        actionId: uuidv4(),
        intention: ParticipantIntention.OFFLINE,
        comment: null,
      })
    } catch (e) {
      setApiError((e as FetchError).message)
      return
    }
    setThanks(true)
  }
  const refuseParticipationHandler = () => {
    setBtnClicked(VariantsBtns.refuse)
    dispatch(popupTriggerActionCreator(PopupState.show))
  }
  const btnChangeDecision = () => {
    setBtnClicked(VariantsBtns.change)
    dispatch(popupTriggerActionCreator(PopupState.show))
  }
  const reasoneRefuseParticipationHandler = async (reasoneRefuse: IReasoneRefuse) => {
    setBtnClicked(undefined)
    dispatch(popupTriggerActionCreator(PopupState.hide))
    if (reasoneRefuse.type === TypeBtnRefuse.save) {
      // //вызов функции отправки решения (Отказ) с причиной
      try {
        await api.participationCommit(participationId, {
          correlationId: uuidv4(),
          actionId: uuidv4(),
          intention: ParticipantIntention.NO,
          comment: reasoneRefuse.description,
        })
      } catch (e) {
        setApiError((e as FetchError).message)
        return
      }
      setThanks(true)
    }
  }

  if (apiError) {
    return (
      <>
        <h1>Что-то пошло не так!</h1>
        <p className="error">{apiError}</p>
      </>
    )
  }

  return (
    <>
      {!thanks ? (
        <div className={styles.container}>
          <div className={styles.content}>
            <p className={styles.description}>Вы приглашены к участию во встрече:</p>
            <div className={styles.meetInfo}>
              <p>Тема встречи:</p>
              <p>{meeting?.subject}</p>
            </div>
            <div className={styles.meetInfo}>
              <p>Организатор:</p>
              <p>{meeting?.organizer?.fio}</p>
            </div>
            <div className={styles.meetInfo}>
              <p>Дата встречи:</p>
              <p>{date}</p>
            </div>
            <div className={styles.meetInfo}>
              <p>Начало:</p>
              <p>{timeFrom}</p>
            </div>
            <div className={styles.meetInfo}>
              <p>Окончание:</p>
              <p>{timeTo}</p>
            </div>
            <div className={styles.meetInfo}>
              <p>Описание:</p>
              <p>{meeting?.description}</p>
            </div>
            <div className={styles.callToAction}>
              {moment().isAfter(meeting?.timeRange.to) ? (
                <p className={styles.meetingIsFinished}> Встреча уже завершилась.</p>
              ) : typePage === TypesPage.confirm ? (
                <div className={styles.wrap}>
                  <p>Пожалуйста, подтвердите свое участие:</p>
                  <div className={styles.btns}>
                    <ThreeButtons
                      text1={VariantsBtns.offline}
                      btn1Click={offlineParticipationHandler}
                      text2={VariantsBtns.online}
                      btn2Click={onlineParticipationHandler}
                      text3={VariantsBtns.refuse}
                      btn3Click={refuseParticipationHandler}
                    />
                  </div>
                </div>
              ) : typePage === TypesPage.change ? (
                <div className={styles.wrap}>
                  <p>Вы подтвердили свое участие {typeParticipation}.</p>
                  <p>Хотите изменить свое решение?</p>
                  <div className={styles.btns}>
                    <Button color={BtnColor.azure} btnClick={btnChangeDecision}>
                      {VariantsBtns.change}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {!thanks ? (
        btnClicked === VariantsBtns.refuse ? (
          <Popup header={VariantsBtns.refuse}>
            <PopupRefuse reasoneRefuseHandler={reasoneRefuseParticipationHandler} />
          </Popup>
        ) : null
      ) : null}
      {!thanks ? (
        btnClicked === VariantsBtns.change ? (
          <Popup header={VariantsBtns.change}>
            <PopupChange
              offlineHandler={offlineParticipationHandler}
              onlineHandler={onlineParticipationHandler}
              refuseHandler={refuseParticipationHandler}
            />
          </Popup>
        ) : null
      ) : null}
      {thanks ? (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.title}>
              <h6>Спасибо!</h6>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Page
